<template>
    <modal ref="modalPedidosCliente" :titulo="`Pedidos del ${$config.cliente} liquidado`" tamano="modal-lg" no-aceptar no-cancelar>
        <cargando v-if="cargando" />
        <div class="row mx-0">
            <div class="col-auto px-3" />
            <div class="col">
                <div class="row mx-0 align-items-center">
                    <img :src="cliente.cliente_foto != null ? cliente.cliente_foto : '/img/no-imagen/sin_cliente.svg'" class="obj-cover rounded-circle" width="36" height="36" />
                    <div class="col text-general f-18">
                        {{ cliente.cliente }}
                    </div>
                </div>
                <div class="row mx-0 py-2" />
                <div class="row mx-0">
                    <div class="col-auto px-4" />
                    <div class="col px-0">
                        <div v-for="pedido in pedidos" :key="pedido.id" class="row mx-0 bg-fondo border br-4 py-2 px-2 mb-2">
                            <img :src="pedido.leechero_logo != null ? pedido.leechero_logo : '/img/no-imagen/sin_cliente.svg'" class="obj-cover br-4 border" width="46" height="46" />
                            <div class="col">
                                <p class="f-15 text-general f-500">{{ separadorNumeroDecimales(pedido.valor_final) }}</p>
                                <p class="f-14 text-general mt-1">{{ pedido.leechero }}</p>
                            </div>
                            <div class="col">
                                <p class="f-15 text-general f-500">No. {{ pedido.id }}</p>
                                <p class="f-14 text-general mt-1">{{ pedido.entrega_fecha | helper-fecha('DD MMM YYYY') }}</p>
                            </div>
                            <div class="col">
                                <p class="f-15 text-general">
                                    <el-tooltip content="Cantidad de Productos" placement="bottom" effect="light">
                                        <i class="icon-product" />
                                    </el-tooltip>
                                    {{ pedido.cantidad_productos }}
                                </p>
                                <p class="f-15 text-general mt-1">
                                    <el-tooltip content="Estado del pedido" placement="bottom" effect="light">
                                        <i class="icon-shopping-check" />
                                    </el-tooltip>
                                    {{ detectarEstado(pedido.estado) }}
                                </p>
                            </div>
                            <div class="col pr-0">
                                <p class="text-general f-15">
                                    <el-tooltip v-if="pedido.entregas != 0" content="Verificado por delivery" placement="bottom" effect="light">
                                        <i class="icon-truck-check text-green" />
                                    </el-tooltip>
                                    <b class="ml-2">Liquidado</b>
                                </p>
                                <p class="text-general f-15 mt-1 text-right">
                                    {{ fechaLiquidacion | helper-fecha('DD MMM YYYY') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto px-3" />
        </div>
    </modal>
</template>

<script>
import Service from '~/services/nomina/historial_liquidacion'
export default {
    data(){
        return {
            cargando: false,
            pedidos: [],
            cliente: {},
            fechaLiquidacion: '',
        }
    },
    methods: {
        async getPedidosClientes(idLiquidacion, idCliente){
            try {
                const params = {
                    id_liquidacion: idLiquidacion
                };
                const {data} = await Service.getPedidosClientes(idCliente, params)
                this.cliente = data.cliente;
                this.pedidos = data.pedidos;
                this.cargando = false;
            } catch (e){
                this.error_catch(e)
            }
        },
        detectarEstado(estado){
            switch (parseInt(estado)){
            case 1:
                return "Confirmado";
            case 201:
            case 202:
                return "Alistado";
            case 3:
                return "Enviado";
            case 4:
            case 31:
            case 32:
            case 33:
            case 34:
                return "Entregado";
            default:
                return "-----";
            }
        },
        toggle(idLiquidacion, idCliente, fechaLiquidacion){
            this.fechaLiquidacion = fechaLiquidacion;
            if(idLiquidacion != null && idCliente != null){
                this.cargando = true;
                this.getPedidosClientes(idLiquidacion, idCliente);
            }
            this.$refs.modalPedidosCliente.toggle();
        }
    }
}
</script>
